/* Catalog */
import { Home as CatalogHome, Catalogs, Catalog } from '../../Catalog';
import ProcessDatesMassiveUpload from 'ProcessDates/MassiveUpload/containers/ProcessDatesMassiveUpload';
import ExchangeRateMassiveUpload from 'ExchangeRate/MassiveUpload/containers/ExchangeRateMassiveUpload';
import ConsultRules from 'Domains/TradeAgreements/pages/ConsultRules';
import UploadMassiveRules from 'Domains/TradeAgreements/pages/UploadMassiveRules';
import RegisterProcessDate from 'Catalog/pages/RegisterProcessDate';
import GetClients from '../../Client/DetailClient/containers';
import RegisterClients from '../../Client/registerClient/containers';
import UpdateClients from '../../Client/updateClient/containers';
import RegisterPrices from '../../Prices/RegisterPrices/Container/RegisterPrices';
import DetailPrices from '../../Prices/DetailPrices/Container/DetailPrice';
import UpdatePrices from '../../Prices/UpdatePrices/Container/UpdatePrices';
import BusinessRules_Register from 'BusinessRules/BRregister/containers/BussinessRules';
import BusinessRules_Update from 'BusinessRules/BRupdate/containers/BussinessRules';
import UpdateBusinessStructure from 'BusinessStructure/UpdateBusinessStructure/Container';
import { UpdateER } from '../../ExchangeRate/UpdateER';
import Index from '../../SalesGoals';
import { ProductRegistration } from 'Products/ProductRegistration';
import { ProductUpdate } from 'Products/ProductUpdate';
import ProductMassiveUpload from 'Products/MassiveUpload/containers/ProductsMassiveUpload';
import BusinessStructureMassiveUpload from 'BusinessStructure/MassiveUpload/containers/BusinessStructureMassiveUpload';
import MassiveUploadPrices from 'Catalog/HistoricInformation/Prices/MassiveUpload/containers/PricesMassiveUpload';
import SalesGoalsMassiveUpload from '../../SalesGoals/MassiveUpload';
import ClientsMassiveUpload from '../../Client/MassiveUpload';
import ModulesTradeAgreementInfo from 'TradeAgreementInformation/ModulesTradeAgreementInfo/ModulesTradeAgreementInfo';
import InventariosIniciales from 'TradeAgreementInformation/StartingInventories/MassiveUpload/containers/InventoriesMassiveUpload';
import ProductMasterMassiveUpload from 'TradeAgreementInformation/ProductMaster/MassiveUpload/containers/ProductMaster';
import PricesListMassiveUpload from 'TradeAgreementInformation/PricesList/MassiveUpload/containers/PricesMassiveUpload';
import DiscountsMassiveUpload from 'TradeAgreementInformation/Discounts/MassiveUpload';

import ConsultAndUpdate from 'Catalog/ProcessDates/ConsultAndUpdate';
export const catalogModulesMap = {
  catalog: CatalogHome,
  /* Business Estructure */
  'business-estructure': Catalogs,
  'consult_business-estructure': Catalogs,
  'update_business-structure': UpdateBusinessStructure,
  'edit_business-estructure': Catalog,
  'detail_business-estructure': Catalog,
  'upload_business-estructure': BusinessStructureMassiveUpload,

  /* Selling Goals */
  'selling-goals': Catalogs,
  'consult_selling-goals': Catalogs,
  'register_selling-goals': Index,
  'update_selling-goals': Catalog,
  'edit_selling-goals': Catalog,
  'detail_selling-goals': Catalog,
  'upload_selling-goals': SalesGoalsMassiveUpload,
  /* Clients */
  clients: Catalogs,
  consult_clients: Catalogs,
  register_clients: RegisterClients,
  update_clients: UpdateClients,
  detail: GetClients,
  edit_clients: Catalog,
  upload_clients: ClientsMassiveUpload,

  /* Products */
  products: Catalogs,
  consult_products: Catalogs,
  register_products: ProductRegistration,
  detail_products: Catalog,
  update_products: ProductUpdate,
  //upload_products: CatalogUpload64,
  upload_products: ProductMassiveUpload,

  /* Prices */
  prices: Catalogs,
  consult_prices: Catalogs,
  register_prices: RegisterPrices,
  detail_prices: DetailPrices,
  edit_prices: Catalog,
  update_prices: UpdatePrices,
  upload_prices: MassiveUploadPrices,

  /* Exchange Rate */
  'exchange-rate': Catalogs,
  'consult_exchange-rate': Catalogs,
  'register_exchange-rate': Catalog,
  'detail_exchange-rate': Catalog,
  'upload_exchange-rate': ExchangeRateMassiveUpload,
  //'upload_exchange-rate': CatalogUpload64,
  'update_exchange-rate': UpdateER,

  /* Process Dates */
  'process-dates': ConsultAndUpdate,
  'consult_process-dates': Catalogs,
  'register_process-dates': RegisterProcessDate,
  'detail_process-dates': RegisterProcessDate,
  'upload_process-dates': ProcessDatesMassiveUpload,
  // 'upload_process-dates': CatalogUpload64,

  /* Business Rules */
  businessRules: ConsultRules,
  register_businessRules: BusinessRules_Register,
  consult_businessRules: ConsultRules,
  detail_businessRules: ConsultRules,
  update_businessRules: BusinessRules_Update,
  upload_businessRules: UploadMassiveRules,

  /* TradeAgreementInfo */
  'trade-agreement-info': ModulesTradeAgreementInfo,
  'upload_trade-agreement-info_starting-inventories': InventariosIniciales,
  'upload_trade-agreement-info_products-master': ProductMasterMassiveUpload,
  'upload_trade-agreement-info_prices-list': PricesListMassiveUpload,
  'upload_trade-agreement-info_discount-table': DiscountsMassiveUpload,
};
