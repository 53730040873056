import React, { useContext, Fragment } from 'react';
import { useState } from 'react';
import './layout.css';
import { navItems, IconsMap } from './utils';
import { useHistory } from 'react-router-dom';
import { Layout, Menu, Modal } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { appContext } from 'context';
const { SubMenu } = Menu;
const { Sider } = Layout;

function Sidenav() {
  let history = useHistory();
  const { onLogout, timeLogout } = useContext(appContext);
  const { modules } = useContext(appContext);
  const Logout = () => {
    Modal.confirm({
      title: 'Cerrar sesión',
      content: '¿Esta seguro que desea cerrar sesión?',
      okText: 'Aceptar',
      cancelText: 'Cancelar',
      centered: true,
      okButtonProps: { type: 'default', style: { backgroundColor: '#003d81', color: '#ffffff' } },
      onOk() {
        onLogout();
        history.push('/login');
      },
      onCancel() {
        return;
      },
    });
  };

  const validateModule = (item) => modules.some((module) => module === item.module.toLowerCase());

  const LogOutIcon = IconsMap['LogoutOutlined'];
  const [collapsedd, setCollapsed] = useState(true);
  const toggleCollapsed = () => {
    setCollapsed(!collapsedd);
  };

  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
      onClick={() => timeLogout()}
    >
      <Sider collapsible collapsed={collapsedd} collapsedWidth={55} width={250} trigger={null}>
        <div style={{ padding: '10px', textAlign: 'center' }}>
          {collapsedd ? (
            <MenuFoldOutlined
              onClick={toggleCollapsed}
              style={{ fontSize: '1.5rem', color: 'white', cursor: 'pointer' }}
            />
          ) : (
            <MenuUnfoldOutlined
              onClick={toggleCollapsed}
              style={{ fontSize: '1.5rem', color: 'white', cursor: 'pointer' }}
            />
          )}
        </div>
        <Menu theme="dark" mode="inline" style={{ backgroundColor: '#00499a' }}>
          {navItems &&
            navItems.map((item, i) => {
              const Icon = IconsMap[item.icon];
              if (!(modules && validateModule(item))) return null;
              if (item.children) {
                return (
                  <Fragment key={`sub${i}`}>
                    <SubMenu
                      title={
                        <span>
                          <Icon
                            style={{
                              fontSize: '1.5rem',
                              alignSelf: 'center',
                              verticalAlign: 'middle',
                              color: 'white',
                            }}
                          />
                          <span
                            style={
                              collapsedd
                                ? { display: 'none' }
                                : { display: 'inline', marginLeft: '1rem', color: 'white' }
                            }
                          >
                            {item.label}
                          </span>
                        </span>
                      }
                    >
                      {item.children.map((childItem, index) => {
                        const SubIcon = IconsMap[childItem.icon];
                        return (
                          <Fragment key={`subitem-${i}-${index}`}>
                            <Menu.Item
                              title={childItem.label}
                              onClick={() => history.push(childItem.link)}
                              style={{ backgroundColor: '#002140' }}
                            >
                              <SubIcon
                                style={{
                                  fontSize: '1.5rem',
                                  alignSelf: 'center',
                                  verticalAlign: 'middle',
                                  color: 'white',
                                }}
                              />
                              <span
                                style={{ display: 'inline', marginLeft: '1rem', color: 'white' }}
                              >
                                {childItem.label}
                              </span>
                            </Menu.Item>
                          </Fragment>
                        );
                      })}
                    </SubMenu>
                  </Fragment>
                );
              } else {
                return (
                  <Fragment key={i}>
                    <Menu.Item title={item.label} onClick={() => history.push(item.link)}>
                      <Icon
                        style={{
                          fontSize: '1.5rem',
                          alignSelf: 'center',
                          verticalAlign: 'middle',
                          color: 'white',
                        }}
                      />
                      <span
                        style={
                          collapsedd
                            ? { display: 'none' }
                            : { display: 'inline', marginLeft: '1rem', color: 'white' }
                        }
                      >
                        {item.label}
                      </span>
                    </Menu.Item>
                  </Fragment>
                );
              }
            })}
          <div className={!collapsedd ? 'sidenav-footer' : 'sidenav-footer-collapsed'}>
            <Menu.Item
              key="100"
              title={'Cerrar Sesión'}
              onClick={() => Logout()}
              // Keep this to avoid "onItemHover" is not a function error.
              // eslint-disable-next-line no-empty-function
              onItemHover={() => {}}
            >
              <LogOutIcon
                style={{
                  fontSize: '1.5rem',
                  color: 'white',
                }}
              />
              <span
                style={
                  collapsedd
                    ? { display: 'none' }
                    : { display: 'inline', marginLeft: '1rem', color: 'white' }
                }
              >
                {'Cerrar Sesión'}
              </span>
            </Menu.Item>
          </div>
        </Menu>
      </Sider>
    </Layout>
  );
}
export default Sidenav;
